import React, { useEffect, useState } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Button, Spin } from 'antd';
import FormSection from './helpers/FormSection';
import { Form, Formik } from 'formik';
import FormField from './helpers/FormField';
import * as Yup from 'yup';
import { phoneRegEx } from '../../utils/constant';
import to from 'await-to-js';
import {
  addNewOutlet,
  fetchOutletById,
  getSingleOutlet,
  isOutletLoading,
  updateOutlet,
} from '../../features/outlets.store';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const OutletsForm = ({ loading, onSubmit, initialValues, title, subTitle, submitBtnText, resetBtnText }) => {
  const history = useHistory();

  return (
    <SubPageLayout title={title} extra={<Button onClick={() => history.goBack()}>Cancel</Button>}>
      <FormSection title={subTitle}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          className="d-flex f-col"
          validationSchema={OutletValidator}
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="outletName" labelText="Outlet Name" placeholder="Input here" />
                <FormField name="outletAddress" labelText="Address" placeholder="Input here" />
                <FormField name="phoneNumber" labelText="Telephone Number" placeholder="+61" />
              </div>
              <div className="flex">
                <FormField name="gpsLat" labelText="GPS Latitude" placeholder="-33.865143" />
                <FormField name="gpsLong" labelText="GPS Longitude" placeholder="151.209900" />
              </div>
            </div>
            <div className="d-flex jc-end space-top-p space-bottom">
              <Button>{resetBtnText}</Button>
              <Button type="primary" htmlType="submit" className="space-left" loading={loading}>
                {submitBtnText}
              </Button>
            </div>
          </Form>
        </Formik>
      </FormSection>
    </SubPageLayout>
  );
};

const OutletValidator = Yup.object({
  outletName: Yup.string().required('Required'),
  outletAddress: Yup.string().required('Required'),
  gpsLong: Yup.number().required('Required'),
  gpsLat: Yup.number().required('Required'),
  phoneNumber: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
});

export const OutletAdd = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isOutletLoading);
  const history = useHistory();

  const handleSave = async (values) => {
    const [err] = await to(dispatch(addNewOutlet(values)).unwrap());
    if (!err) {
      history.push('/dashboard/outlets');
    }
  };

  return (
    <OutletsForm
      loading={loading}
      onSubmit={handleSave}
      title="Add New Outlet"
      subTitle="Add your new outlet information and submit"
      resetBtnText="Clear"
      submitBtnText="Add"
      initialValues={{
        outletName: '',
        outletAddress: '',
        phoneNumber: '',
        gpsLat: '',
        gpsLong: '',
      }}
    />
  );
};

export const OutletEdit = () => {
  const { outletCode } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(isOutletLoading);
  const outlet = useSelector(getSingleOutlet);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchOutletById({ id: outletCode }));
  }, [outletCode]);

  const handleSave = async (value) => {
    const [err] = await to(dispatch(updateOutlet({ outletCode, value })).unwrap());
    if (!err) {
      history.push('/dashboard/outlets');
    }
  };

  return (
    <Spin spinning={loading}>
      {!loading && outlet && (
        <OutletsForm
          loading={loading}
          resetBtnText="Reset"
          submitBtnText="Update"
          onSubmit={handleSave}
          title="Edit Outlet Info"
          subTitle="Update the values and submit"
          initialValues={outlet}
        />
      )}
    </Spin>
  );
};
