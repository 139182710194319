import { useSelector } from 'react-redux';
import { isFeatureEnabled } from '../features/auth.store';

export const ROLE = {
  admin: 'admin',
  employee: 'employee',
};

const FEATURES = {
  view_users: [ROLE.admin],

  view_foods: [ROLE.admin, ROLE.employee],
  add_foods: [ROLE.admin],
  edit_foods: [ROLE.admin],
  enable_disabled_foods: [ROLE.employee],

  view_outlet: [ROLE.admin, ROLE.employee],
  add_outlet: [ROLE.admin],
  edit_outlet: [ROLE.admin],

  view_addons: [ROLE.admin],
  view_promos: [ROLE.admin],
  view_highlights: [ROLE.admin],

  view_orders: [ROLE.employee],
};

export const fromFeatureMatrix = (group, key) => {
  if (FEATURES[key] && FEATURES[key].length > 0) {
    return FEATURES[key].indexOf(group) >= 0;
  }
  return true;
};

export const useFeature = (key) => useSelector(isFeatureEnabled)(key);
