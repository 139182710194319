export const resizeCloudinaryImages = (url, width = 0, height = 0) => {
  if (!url) {
    return url;
  }
  if (!url.startsWith('https://res.cloudinary.com')) {
    return url;
  }
  const r = [width ? 'w_' + width : '', height ? 'h_' + height : ''].filter((a) => !!a);
  return url.replace(/\/v\d{10}\//g, `/${r.join(',')}/`);
};
