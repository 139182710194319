import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import to from "await-to-js";
import {Select} from "antd";
import get from 'lodash.get';

const { Option } = Select;

export const SearchFilterDropdown = ({ onChange, selectFirstOnLoad, dataSelector, loadingSelector, fetchAction, identifier, labelKey, ...props }) => {
  const dispatch = useDispatch();
  const data = useSelector(dataSelector);
  const loading = useSelector(loadingSelector);
  const [value, setValue] = useState(props.value);

  const handleOnChange = (value) => {
    setValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    (async () => {
      const [err, data] = await to(dispatch(fetchAction()).unwrap());
      if (!err && selectFirstOnLoad) {
        const firstElement = get(data, [0, identifier]);
        setValue(firstElement);
        onChange && onChange(firstElement);
      }
    })();
  }, []);

  return (
    <Select
      showSearch
      optionFilterProp="children"
      className="w-100"
      value={value}
      loading={loading}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={handleOnChange}
      {...props}
    >
      {data.map((obj) => (
        <Option value={obj[identifier]} key={obj[identifier]}>
          {obj[labelKey]}
        </Option>
      ))}
    </Select>
  );
};