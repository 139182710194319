import { toast } from 'react-toastify';

export const thunkResult = (err, data, rejectWithValue, errMessage = 'Failed', successMessage = '') => {
  if (err) {
    toast.error(errMessage);
    return rejectWithValue(err);
  }
  if (successMessage) {
    toast.success(successMessage);
  }
  return data;
};
