import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import APIService from '../utils/APIService';
import get from 'lodash.get';

const initialState = {
  'order-statuses': null,
  'food-categories': null,
  'dietary-preferences': null,
};

export const getEnums = createAsyncThunk('fetch/enum', async ({ type }, { getState }) => {
  const values = enumState(getState())[type];
  if (!values) {
    const values = await APIService.get(`enum/${type}`);
    return { type, values };
  }
  return {};
});

const enumSlice = createSlice({
  name: 'enums',
  initialState,
  reducers: {},
  extraReducers: {
    [getEnums.fulfilled]: (state, { payload: { type, values } }) => {
      if (values) {
        state[type] = values.reduce((p, c) => {
          p[c.key] = c.displayName;
          return p;
        }, {});
      }
    },
  },
});

export default enumSlice.reducer;

const enumState = (state) => state.enums;

export const getAllEnum = createSelector(enumState, (state) => state);
export const getEnumObj = createSelector(enumState, (state) => (type) => state[type] || {});
export const getFoodCategoriesArray = createSelector(enumState, (state) => {
  const obj = get(state, 'food-categories') || {};
  return Object.keys(obj).map((value) => ({ value, label: obj[value] }));
});
export const getOrderStatus = createSelector(enumState, (state) => {
  const obj = get(state, 'order-statuses') || {};
  return Object.keys(obj).map((value) => ({ value, label: obj[value] }));
});
