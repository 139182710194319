import React, { useEffect, useState } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Avatar, Button, Col, Collapse, Empty, Image, Modal, Row, Select, Spin, Tabs, Tooltip } from 'antd';
import ListItemHeader from './helpers/ListItemHeader';
import { MdCardGiftcard } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  assignAddonToGroup,
  fetchAllAddOnGroups,
  fetchAllAddons,
  getAddonGroupLoading,
  getAddonLoading,
  getAllAddonGroup,
  getAllAddonItems,
  releaseItemFromGroup,
} from '../../features/addon.store';
import { AddonForm, AddonGroupForm } from './helpers/addon-forms';
import { resizeCloudinaryImages } from '../../utils/images';
import { CloseOutlined } from '@ant-design/icons';

import './helpers/styles/addon.scss';
import to from 'await-to-js';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Option } = Select;

const AddOnItems = () => {
  const addonItems = useSelector(getAllAddonItems);
  const loading = useSelector(getAddonLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAddons());
  }, []);

  return (
    <Spin spinning={loading}>
      {addonItems.length > 0 ? (
        <Collapse accordion ghost expandIconPosition="right" collapsible="header">
          {addonItems.map((item) => (
            <Panel
              key={item.addOnItemCode}
              header={<ListItemHeader icon={<MdCardGiftcard />} title={item.name} subTitle={item.price} />}
              className="list-item"
            >
              <Image
                src={resizeCloudinaryImages(item.imageUrl, 0, 200)}
                alt={item.imageUrl}
                preview={{ src: item.imageUrl }}
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <Empty />
      )}
    </Spin>
  );
};

const GroupAddonItems = ({ group }) => {
  const [adding, setAdding] = useState(false);
  const dispatch = useDispatch();
  const availableAddons = useSelector(getAllAddonItems);

  const changeAddingState = () => {
    setAdding(true);
    dispatch(fetchAllAddons());
  };

  const handelSelectAddon = async (value) => {
    const [err] = await to(dispatch(assignAddonToGroup({ itemCode: value, groupCode: group.addOnGroupCode })).unwrap());
    if (!err) {
      setAdding(false);
    }
  };

  const handleRelease = (itemCode) => {
    Modal.confirm({
      title: 'Are you sure?',
      content: 'Do you want to release this item from the group?',
      onOk: () => {
        dispatch(releaseItemFromGroup({ itemCode }));
      },
    });
  };

  return (
    <Row className="w-100">
      {group.addOnItems.map((a) => (
        <Col span={12} key={a.addOnItemCode}>
          <div className="addon-item d-flex ai-center jc-space-between">
            <div className="d-flex ai-center">
              <Avatar src={resizeCloudinaryImages(a.imageUrl, 50, 50)} className="space-right" />
              <div>
                <div className="addon-name">{a.name}</div>
                <div>{a.price}$</div>
              </div>
            </div>
            <div>
              <Tooltip title="Release">
                <Button
                  onClick={() => handleRelease(a.addOnItemCode)}
                  danger
                  size="small"
                  shape="circle"
                  icon={<CloseOutlined />}
                  className="remove-button"
                />
              </Tooltip>
            </div>
          </div>
        </Col>
      ))}
      <Col span={12}>
        <div className="add-new-addon d-flex jc-center ai-center h-100">
          {adding ? (
            <Select
              showSearch
              placeholder="Select an addon"
              optionFilterProp="children"
              onChange={handelSelectAddon}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {availableAddons.map((a) => (
                <Option value={a.addOnItemCode} key={a.addOnItemCode}>
                  {a.name} - {a.price}$
                </Option>
              ))}
            </Select>
          ) : (
            <Button onClick={changeAddingState}>Add New</Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

const AddOnGroups = () => {
  const addonGroups = useSelector(getAllAddonGroup);
  const loading = useSelector(getAddonGroupLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllAddOnGroups());
  }, []);

  return (
    <Spin spinning={loading}>
      {addonGroups.length > 0 ? (
        <Collapse accordion ghost expandIconPosition="right" collapsible="header">
          {addonGroups.map((group) => (
            <Panel
              key={group.addOnGroupCode}
              header={<ListItemHeader icon={<MdCardGiftcard />} title={group.name} />}
              className="list-item"
              extra={`Addon Items: ${group.addOnItems.length}`}
            >
              <GroupAddonItems group={group} />
            </Panel>
          ))}
          {addonGroups.length === 0 && <Empty />}
        </Collapse>
      ) : (
        <Empty />
      )}
    </Spin>
  );
};

const AddOns = () => {
  const [active, setActive] = useState('groups');

  return (
    <SubPageLayout title="Food Promotions" extra={[<AddonForm key="1" />, <AddonGroupForm key="2" />]}>
      <Tabs
        type="card"
        activeKey={active}
        onChange={(key) => {
          setActive(key);
        }}
      >
        <TabPane tab="Add On Groups" key="groups" animated>
          <AddOnGroups />
        </TabPane>
        <TabPane tab="Free Add On Items" key="items">
          {active === 'items' && <AddOnItems />}
        </TabPane>
      </Tabs>
    </SubPageLayout>
  );
};

export default AddOns;
