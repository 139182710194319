import React from 'react';
import { Avatar, Modal, Popover } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import { MdOutlineStorefront, MdOutlineShoppingBag, MdCardGiftcard, MdStarOutline } from 'react-icons/md';
import { BiUser, BiLogOut } from 'react-icons/bi';
import { GiHamburger } from 'react-icons/gi';
import './styles/layout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, logout } from '../../../features/auth.store';
import { useFeature } from '../../../utils/features';

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    Modal.confirm({
      title: 'Logout ?',
      content: 'Are you sure, you want to log out?',
      onOk: async () => {
        await dispatch(logout());
      },
    });
  };

  const userData = useSelector(getUser);
  let avatarname = '';
  if (userData && userData.name) {
    const names = userData.name.split(' ');
    if (names.length === 1) {
      avatarname = names[0].charAt(0);
    } else {
      avatarname = names[0].charAt(0) + names[1].charAt(0);
    }
  }

  const showOutlet = useFeature('view_outlet');
  const viewUsers = useFeature('view_users');
  const viewOrders = useFeature('view_orders');
  const viewFoods = useFeature('view_foods');
  const viewPromos = useFeature('view_promos');
  const viewAddons = useFeature('view_addons');
  const viewHighlight = useFeature('view_highlights');

  return (
    <div className="layout d-flex f-col mulish">
      <div className="header d-flex f-row jc-space-between">
        <img className="logo" src="/logo.png" alt="logo" />
        <Popover
          title="User Account"
          placement="bottomRight"
          content={
            <div>
              <Link to="/profile">Edit Profile</Link>
            </div>
          }
        >
          <div className="profile-dropdown d-flex ai-center jc-space-between">
            <Avatar icon={!avatarname && <UserOutlined />}>{avatarname.toUpperCase()}</Avatar>
          </div>
        </Popover>
      </div>
      <div className="body h-100 d-flex">
        <div className="side-bar h-100 d-flex f-col">
          <div className="side-bar-menu d-flex f-col jc-space-between">
            <div className="main-menus">
              {showOutlet && (
                <NavLink to="/outlets" className="menu-item">
                  <MdOutlineStorefront className="icon" />
                  <div className="title">Outlets</div>
                </NavLink>
              )}
              {viewUsers && (
                <NavLink to="/users" className="menu-item">
                  <BiUser className="icon" />
                  <div className="title">Users</div>
                </NavLink>
              )}
              {viewOrders && (
                <NavLink to="/orders" className="menu-item">
                  <MdOutlineShoppingBag className="icon" />
                  <div className="title">Orders</div>
                </NavLink>
              )}
              {viewFoods && (
                <NavLink to="/foods" className="menu-item">
                  <GiHamburger className="icon" />
                  <div className="title">Foods</div>
                </NavLink>
              )}
              {viewAddons && (
                <NavLink to="/addons" className="menu-item">
                  <GiHamburger className="icon" />
                  <div className="title">Add on / Groups</div>
                </NavLink>
              )}
              {viewPromos && (
                <NavLink to="/promos" className="menu-item">
                  <MdCardGiftcard className="icon" />
                  <div className="title">Promos</div>
                </NavLink>
              )}
              {viewHighlight && (
                <NavLink to="/highlights" className="menu-item">
                  <MdStarOutline className="icon" />
                  <div className="title">Highlights</div>
                </NavLink>
              )}
            </div>

            <a className="menu-item logout" onClick={handleLogout}>
              <BiLogOut className="icon" />
              <div className="title">Logout</div>
            </a>
          </div>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
