import React, { useEffect } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Link } from 'react-router-dom';
import { Button, Collapse, Image, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ListItemHeader from './helpers/ListItemHeader';
import { MdCardGiftcard } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableDisabledPromotion,
  fetchAllPromos,
  getAllPromos,
  getPromosAndHighlightLoading,
} from '../../features/promos-highlights.store';

const { Panel } = Collapse;

const Promotions = () => {
  const loading = useSelector(getPromosAndHighlightLoading);
  const allPromos = useSelector(getAllPromos);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllPromos());
  }, []);

  const handleEnableDisabled = (code, enabled) => {
    dispatch(enableDisabledPromotion({ code, enabled }));
  };

  return (
    <SubPageLayout
      title="Food Promotions"
      extra={
        <Link to="promos/add">
          <Button type="primary" icon={<PlusOutlined />}>
            Add New
          </Button>
        </Link>
      }
      loading={loading}
    >
      <Collapse accordion ghost expandIconPosition="right" collapsible="header">
        {allPromos.map((promo) => (
          <Panel
            key={promo.promotionCode}
            header={<ListItemHeader icon={<MdCardGiftcard />} title={promo.promotionName} />}
            extra={[
              <Switch
                key="enable-disabled-switch"
                onChange={(checked) => {
                  handleEnableDisabled(promo.promotionCode, checked);
                }}
                checkedChildren="ENABLE"
                unCheckedChildren="DISABLE"
                checked={promo.enabled}
              />,
            ]}
            className="list-item"
          >
            <Image src={promo.promotionImageUrl} alt={promo.promotionImageUrl} />
          </Panel>
        ))}
      </Collapse>
    </SubPageLayout>
  );
};

export default Promotions;
