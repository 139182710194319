import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService from '../utils/APIService';
import { thunkResult } from '../utils/thunkResult';
import { getUserGroup } from './auth.store';

const initialState = {
  outlets: [],
  singleOutlet: null,
  loading: false,
};

export const fetchPaginatedOutlets = createAsyncThunk('outlet/fetch', async (_, { rejectWithValue, getState }) => {
  const group = getUserGroup(getState());
  let url = 'outlet';
  if (group === 'admin') {
    url = 'admin/outlet';
  }
  const [err, data] = await to(APIService.get(url));
  return thunkResult(err, group === 'admin' ? data : [data], rejectWithValue, eng.unableToFetchData);
});

export const fetchAllOutlets = createAsyncThunk('outlet/fetch-all', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/outlet'));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const fetchOutletById = createAsyncThunk('outlet/fetch-by-id', async ({ id }, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get(`admin/outlet/${id}`));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

// admin only
export const addNewOutlet = createAsyncThunk('outlet/add', async (values, { rejectWithValue }) => {
  const [err, data] = await to(APIService.post('admin/outlet', values));
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.successfullyAdded);
});

export const updateOutlet = createAsyncThunk('outlet/update', async ({ outletCode, value }, { rejectWithValue }) => {
  const [err, data] = await to(APIService.put(`/admin/outlet/${outletCode}`, value));
  return thunkResult(err, data, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
});

export const enableDisabledOutlet = createAsyncThunk(
  'outlet/enable-disable',
  async ({ code, enabled }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`/admin/outlet/${enabled ? 'enable' : 'disable'}/${code}`));
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const outletsSlice = createSlice({
  name: 'outlets',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPaginatedOutlets.pending]: (store) => {
      store.loading = true;
    },
    [fetchPaginatedOutlets.rejected]: (store) => {
      store.loading = false;
      store.outlets = [];
    },
    [fetchPaginatedOutlets.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.outlets = payload;
    },
    [fetchAllOutlets.pending]: (store) => {
      store.loading = true;
    },
    [fetchAllOutlets.rejected]: (store) => {
      store.loading = false;
      store.outlets = [];
    },
    [fetchAllOutlets.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.outlets = payload;
    },
    [fetchOutletById.pending]: (store) => {
      store.loading = true;
    },
    [fetchOutletById.rejected]: (store) => {
      store.loading = false;
      store.singleOutlet = null;
    },
    [fetchOutletById.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.singleOutlet = payload;
    },
    [addNewOutlet.pending]: (store) => {
      store.loading = true;
    },
    [addNewOutlet.rejected]: (store) => {
      store.loading = false;
    },
    [addNewOutlet.fulfilled]: (store) => {
      store.loading = false;
    },
    [enableDisabledOutlet.pending]: (store) => {
      store.loading = true;
    },
    [enableDisabledOutlet.rejected]: (store) => {
      store.loading = false;
    },
    [enableDisabledOutlet.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.outlets.filter((a) => a.outletCode === payload.code)[0].enabled = payload.enabled;
    },
  },
});

const getOutletsStore = (state) => state.outlets;

// selectors
export const getOutlets = createSelector(getOutletsStore, (store) => store.outlets || []);
export const getSingleOutlet = createSelector(getOutletsStore, (store) => store.singleOutlet);
export const isOutletLoading = createSelector(getOutletsStore, (store) => store.loading);

export default outletsSlice.reducer;
