import React, { useEffect, useRef, useState } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import ListItemHeader from './helpers/ListItemHeader';
import { MdStarOutline } from 'react-icons/md';
import { Button, Collapse, Image, Switch, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPaginatedOrders,
  getOrderList,
  getOrderListUpdatedAt,
  getOrderLoading,
  getType,
  setCurrentType,
} from '../../features/orders.store';
import { getOrderStatus } from '../../features/enums.store';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const Orders = () => {
  const type = useSelector(getType);
  const [autoUpdate, setAutoUpdate] = useState(false);
  const loading = useSelector(getOrderLoading);
  const orderList = useSelector(getOrderList);
  const orderStatus = useSelector(getOrderStatus);
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const lastUpdatedAt = useSelector(getOrderListUpdatedAt);

  useEffect(() => {
    dispatch(fetchPaginatedOrders());
  }, [type]);

  useEffect(() => {
    if (autoUpdate) {
      intervalRef.current = setInterval(() => {
        dispatch(fetchPaginatedOrders());
      }, 2000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [autoUpdate]);

  const handleOpenOrder = (order) => {};

  return (
    <SubPageLayout
      title="Orders"
      loading={loading}
      extra={
        <div>
          Last Updated At: {lastUpdatedAt} | Auto Update: <Switch checked={autoUpdate} onChange={setAutoUpdate} />
        </div>
      }
    >
      <Tabs type="card" size="large" onChange={(t) => dispatch(setCurrentType(t))} activeKey={type}>
        {orderStatus.map(({ label, value }) => (
          <TabPane tab={label} key={value} className="p-5">
            <Collapse accordion ghost expandIconPosition="right" collapsible="header">
              {orderList.map((order) => (
                <Panel
                  key={order.orderCode}
                  header={<ListItemHeader icon={<MdStarOutline />} title={order.orderNumber} />}
                  extra={
                    <Button type={order.isEnabled ? 'primary' : 'default'} onClick={() => handleOpenOrder(order)}>
                      View
                    </Button>
                  }
                  className="list-item"
                />
              ))}
            </Collapse>
          </TabPane>
        ))}
      </Tabs>
    </SubPageLayout>
  );
};

export default Orders;
