import React, {useRef, useState} from 'react';
import PublicPageLayout from './public-page-layout';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import history from "../../utils/history";

const ForgetPassword = () => {
  const [isLoading, setLoading] = useState(false);
  const [restPassword, setRestPassword] = useState(false);
  const emailRef = useRef();

  const handleForgetPassword = async ({ email }) => {
    setLoading(true);
    const [err, data] = await to(Auth.forgotPassword(email));
    emailRef.current = email;
    setLoading(false);
    setRestPassword(true);
    if (err) {
      return toast.error('Something went wrong!');
    }
    toast.success('Your Reset Password link is sent. check your inbox.');
  };

  const handleRestPassword = async ({password, confirm, code}) => {
    if (password !== confirm) {
      toast.error('Password mismatch');
    }
    setLoading(true);
    const [err, data] = await to(Auth.forgotPasswordSubmit(emailRef.current, code, password));
    setLoading(false);
    if (err) {
      return toast.error('Something went wrong!');
    }
    toast.success('Password changed successfully, you can re-login now');
    history.push('login');
  }

  return (
    <PublicPageLayout>
      <div className="title fw-700">Hello! </div>
      <div className="sub-title fw-400">Did you forget your password?</div>
      <div className="sub-title fw-400">Enter your email address. We will send you a password reset code!</div>

      {restPassword ?
        <Formik
          initialValues={{ password: '', confirm: '', code: '' }}
          onSubmit={handleRestPassword}
          className="d-flex f-col"
          validateOnBlur
          validationSchema={Yup.object({
            password: Yup.string().required('Required').min(6, 'Select a strong password'),
            confirm: Yup.string().oneOf([Yup.ref('password')], 'Confirmation Failed'),
            code: Yup.string().required(),
          })}
        >
          <Form>
            <div className="login-field">
              <label htmlFor="code">Password Reset OTP</label>
              <Field name="code">
                {({ field, meta }) => (
                  <Input
                    className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                    suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="login-field">
              <label htmlFor="password">New Password</label>
              <Field name="password">
                {({ field, meta }) => (
                  <Input.Password
                    className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                    suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="login-field">
              <label htmlFor="confirm">Enter the Password again</label>
              <Field name="confirm">
                {({ field, meta }) => (
                  <Input.Password
                    className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                    suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="remember-me-forgot-password d-flex jc-space-between ai-center">
              <Button disabled={isLoading} className="upper-case" type="primary" size="large" htmlType="submit">
                Reset Password
              </Button>
              <Link to="/login">Login</Link>
            </div>
          </Form>
        </Formik>
        :
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleForgetPassword}
          className="d-flex f-col"
          validateOnBlur
          validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
          })}
        >
          <Form>
            <div className="login-field">
              <label htmlFor="email">Email ID</label>
              <Field name="email">
                {({ field, meta }) => (
                  <Input
                    placeholder="Enter your email"
                    className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                    suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <div className="remember-me-forgot-password d-flex jc-space-between ai-center">
              <Button disabled={isLoading} className="upper-case" type="primary" size="large" htmlType="submit">
                Submit
              </Button>
              <Link to="/login">Login</Link>
            </div>
          </Form>
        </Formik>
      }
    </PublicPageLayout>
  );
};

export default ForgetPassword;
