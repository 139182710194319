import { configureStore, combineReducers } from '@reduxjs/toolkit';
import auth from './features/auth.store';
import outlets from './features/outlets.store';
import users from './features/users.store';
import promosHighlights from './features/promos-highlights.store';
import enums from './features/enums.store';
import foods from './features/foods.store';
import addon from './features/addon.store';
import order from './features/orders.store';

const rootReducer = combineReducers({
  auth,
  outlets,
  users,
  promosHighlights,
  enums,
  foods,
  addon,
  order,
});

// reset the store as here: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
export const store = configureStore({
  reducer: (state, action) => {
    if (action.type === 'USER_LOGOUT') {
      return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
  },
});
