import React, { useEffect, useState } from 'react';
import { Avatar, Checkbox, Drawer, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addRemoveAddonGroup, getManageAddonsFor, setManageAddonFor } from '../../../features/foods.store';
import { SearchOutlined } from '@ant-design/icons';
import { fetchAllAddOnGroups, getAddonGroupLoading, getAllAddonGroup } from '../../../features/addon.store';
import { resizeCloudinaryImages } from '../../../utils/images';
import './styles/addon.scss';

const AddonManager = () => {
  const foodForManageAddon = useSelector(getManageAddonsFor);
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const addonGroups = useSelector(getAllAddonGroup);
  const addonGroupsLoading = useSelector(getAddonGroupLoading);

  useEffect(() => {
    dispatch(fetchAllAddOnGroups());
  }, []);

  const handleAddRemoveAddonGroup = (value, code) => {
    dispatch(addRemoveAddonGroup({ enabled: value, code }));
  };

  let groupsOnSelectedFood = {};

  if (foodForManageAddon && foodForManageAddon.addOnGroups) {
    groupsOnSelectedFood = foodForManageAddon.addOnGroups.reduce((p, c) => {
      p[c.addOnGroupCode] = true;
      return p;
    }, {});
  }

  return (
    <Drawer width={500} visible={!!foodForManageAddon} onClose={() => dispatch(setManageAddonFor(null))}>
      <Spin spinning={addonGroupsLoading}>
        <Input
          size="large"
          placeholder="Search Groups by Name"
          prefix={<SearchOutlined />}
          allowClear
          onChange={(e) => setSearch(e.target.value)}
        />
        <div style={{ height: 'calc(100vh - 150px)', overflow: 'auto' }}>
          {addonGroups
            .filter((group) => group.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
            .map((group) => (
              <div
                className={`addon-group ${groupsOnSelectedFood[group.addOnGroupCode] ? 'active' : ''}`}
                key={group.addOnGroupCode}
              >
                <Checkbox
                  checked={groupsOnSelectedFood[group.addOnGroupCode]}
                  onChange={(e) => handleAddRemoveAddonGroup(e.target.checked, group.addOnGroupCode)}
                >
                  {group.name}
                </Checkbox>
                {group.addOnItems.map((item) => (
                  <div className="addon-item d-flex ai-center jc-space-between" key={item.addOnItemCode}>
                    <div className="d-flex ai-center">
                      <Avatar src={resizeCloudinaryImages(item.imageUrl, 50, 50)} className="space-right" />
                      <div>
                        <div className="addon-name">{item.name}</div>
                        <div>{item.price}$</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </Spin>
    </Drawer>
  );
};

export default AddonManager;
