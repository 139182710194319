import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input, Modal } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn, getLoginInProgress, loginUser } from '../../features/auth.store';
import history from '../../utils/history';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import PublicPageLayout from './public-page-layout';
import to from 'await-to-js';
import { toast } from 'react-toastify';
import eng from '../../utils/eng';
import FormField from '../Dashboard/helpers/FormField';
import { Auth } from '@aws-amplify/auth';

const Login = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const [changeFirstPass, setChangeFirstPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const changePassRef = useRef();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn]);

  const handleLogin = async (values) => {
    const [err, data] = await to(dispatch(loginUser(values)).unwrap());
    if (err) {
      if (err.forceChangePassword) {
        setChangeFirstPass(true);
      }
      return toast.error(err.status === 401 ? eng.invalidUsernamePassword : err.message);
    }
    history.push('/dashboard');
    toast.success(eng.loginSuccess);
  };

  const handleChangePassword = async (values) => {
    setLoading(true);
    const { newPass, confirmPass } = values;
    // validation
    if (newPass !== confirmPass) {
      setLoading(false);
      return toast.warn('Password does not match!');
    }

    const [err, data] = await to(Auth.completeNewPassword(window._tempUserRef, newPass));
    if (err) {
      console.log(err.message);
      toast.error(`Something went wrong. ${err.message}`);
    } else if (data) {
      toast.success('Password updated!');
      setChangeFirstPass(false);
    }
    setLoading(false);
  };

  const isLoginInProgress = useSelector(getLoginInProgress);

  return (
    <PublicPageLayout>
      <div className="title fw-700">Hello! </div>
      <div className="sub-title fw-400">Sign in to continue!</div>

      <Modal visible={changeFirstPass} title="Change Your Password!" onOk={() => changePassRef.current.submitForm()}>
        <Formik
          initialValues={{ newPass: '', confirmPass: '' }}
          onSubmit={handleChangePassword}
          className="d-flex f-col"
          validateOnBlur
          validationSchema={Yup.object({
            newPass: Yup.string().required('Required'),
            confirmPass: Yup.string().required('Required'),
          })}
          innerRef={changePassRef}
        >
          <Form>
            <FormField
              type="password"
              name="newPass"
              labelText="Current Password"
              InputComp={Input.Password}
              visibilityToggle={false}
              placeholder="Input here"
            />
            <FormField
              type="password"
              name="confirmPass"
              labelText="Current Password"
              InputComp={Input.Password}
              visibilityToggle={false}
              placeholder="Input here"
            />
          </Form>
        </Formik>
      </Modal>

      <Formik
        initialValues={{ email: 'sedarof742@shopxda.com', password: 'Test@123', rememberMe: true }}
        onSubmit={handleLogin}
        className="d-flex f-col"
        validateOnBlur
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Required'),
          password: Yup.string().required('Required'),
        })}
      >
        <Form>
          <div className="login-field">
            <label htmlFor="email">Email ID</label>
            <Field name="email">
              {({ field, meta }) => (
                <Input
                  placeholder="Enter your email"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  suffix={meta.touched ? meta.error ? <CloseOutlined /> : <CheckOutlined /> : <span />}
                  {...field}
                />
              )}
            </Field>
          </div>
          <div className="login-field">
            <label htmlFor="password">Password</label>
            <Field name="password">
              {({ field, meta }) => (
                <Input.Password
                  placeholder="Enter your password"
                  className={`${meta.touched && meta.error ? 'has-error' : ''}`}
                  {...field}
                />
              )}
            </Field>
          </div>

          <div className="remember-me-forgot-password d-flex jc-space-between">
            <Link to="/forgot-password">Forgot Password ?</Link>
          </div>

          <Button disabled={isLoginInProgress} className="upper-case" type="primary" size="large" htmlType="submit">
            Login to continue
          </Button>
        </Form>
      </Formik>
    </PublicPageLayout>
  );
};

export default Login;
