import React from 'react';
import './login-styles.scss';

const PublicPageLayout = ({ children }) => {
  return (
    <div className="login-container d-flex f-row h-100 ai-center jc-center poppins">
      <div className="left-panel">
        <img src="/backdrop.png" alt="minearc logo" />
      </div>
      <div className="right-panel">
        <div className="form-container">{children}</div>
      </div>
    </div>
  );
};

export default PublicPageLayout;
