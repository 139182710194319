import React, { useEffect, useRef } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Button, Input, Spin } from 'antd';
import FormSection from './helpers/FormSection';
import { Form, Formik } from 'formik';
import FormField from './helpers/FormField';
import * as Yup from 'yup';
import to from 'await-to-js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  addNewFoodItem,
  fetchFoodItemById,
  getSingleFoodItem,
  isFoodItemsLoading,
  updateFoodItem,
} from '../../features/foods.store';
import { DietaryPreferencesDropdown, FoodCategoriesDropdown } from './helpers/enums-dropdown';
import { Uploader } from './helpers/Uploader';
import get from 'lodash.get';

const FoodForms = ({ loading, onSubmit, initialValues, title, subTitle, submitBtnText, resetBtnText }) => {
  const history = useHistory();
  const imageUploadRef = useRef();

  return (
    <SubPageLayout title={title} extra={<Button onClick={() => history.goBack()}>Cancel</Button>}>
      <FormSection title={subTitle}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => onSubmit(values, imageUploadRef)}
          className="d-flex f-col"
          validationSchema={FoodValidator}
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="foodName" labelText="Food Name" placeholder="Input here" />
                <FormField name="price" labelText="Price" placeholder="Input here" />
                <FormField
                  name="foodCategory"
                  labelText="Food Category"
                  placeholder="Select"
                  InputComp={FoodCategoriesDropdown}
                />
                <FormField
                  name="dietaryPreferences"
                  labelText="Dietary Preferences"
                  placeholder="Select"
                  InputComp={DietaryPreferencesDropdown}
                />
                <FormField
                  name="description"
                  labelText="Description"
                  placeholder="Input Here"
                  InputComp={Input.TextArea}
                  col={4}
                />
              </div>
              <div className="flex">
                <FormField name="foodNutritionCalories" labelText="Nutrition Calories" placeholder="Input here" />
                <FormField name="foodNutritionCarbs" labelText="Nutrition Carbs" placeholder="Input here" />
                <FormField name="foodNutritionProtein" labelText="Nutrition Protein" placeholder="Input here" />
                <FormField name="foodNutritionFat" labelText="Nutrition Fat" placeholder="Input here" />
                <Uploader
                  accept="image/*"
                  beforeUpload={() => Promise.reject()}
                  className="w-200px h-200px"
                  maxCount={1}
                  ref={imageUploadRef}
                />
              </div>
            </div>
            <div className="d-flex jc-end space-top-p space-bottom">
              <Button>{resetBtnText}</Button>
              <Button type="primary" htmlType="submit" className="space-left" loading={loading}>
                {submitBtnText}
              </Button>
            </div>
          </Form>
        </Formik>
      </FormSection>
    </SubPageLayout>
  );
};

const FoodValidator = Yup.object({
  foodName: Yup.string().required('Required'),
  price: Yup.number().min(0).required('Required'),
  foodCategory: Yup.string().required('Required'),
  dietaryPreferences: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  foodNutritionCalories: Yup.string().required('Required'),
  foodNutritionCarbs: Yup.string().required('Required'),
  foodNutritionProtein: Yup.string().required('Required'),
  foodNutritionFat: Yup.string().required('Required'),
});

export const FoodsAdd = () => {
  const dispatch = useDispatch();
  const loading = useSelector(isFoodItemsLoading);
  const history = useHistory();

  const handleSave = async (values, imageRef) => {
    const image = get(imageRef, ['current', 'fileList', 0, 'originFileObj'], null);
    const [err] = await to(dispatch(addNewFoodItem({ values, image })).unwrap());
    if (!err) {
      history.push('/dashboard/foods');
    }
  };

  return (
    <FoodForms
      loading={loading}
      onSubmit={handleSave}
      title="Add New Food Item"
      subTitle="Add your new food item information and submit"
      resetBtnText="Clear"
      submitBtnText="Add"
      initialValues={{
        foodName: '',
        price: '',
        foodCategory: '',
        dietaryPreferences: '',
        description: '',
        foodNutritionCalories: '',
        foodNutritionCarbs: '',
        foodNutritionProtein: '',
        foodNutritionFat: '',
      }}
    />
  );
};

export const FoodsEdit = () => {
  const { foodCode } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector(isFoodItemsLoading);
  const outlet = useSelector(getSingleFoodItem);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchFoodItemById({ id: foodCode }));
  }, [foodCode]);

  const handleSave = async (value, imageRef) => {
    const image = get(imageRef, ['current', 'fileList', 0, 'originFileObj'], null);
    const [err] = await to(dispatch(updateFoodItem({ foodCode, value, image })).unwrap());
    if (!err) {
      history.push('/dashboard/foods');
    }
  };

  return (
    <Spin spinning={loading}>
      {!loading && outlet && (
        <FoodForms
          loading={loading}
          resetBtnText="Reset"
          submitBtnText="Update"
          onSubmit={handleSave}
          title="Edit Outlet Info"
          subTitle="Update the values and submit"
          initialValues={outlet}
        />
      )}
    </Spin>
  );
};
