import React from 'react';
import './styles/list-item.scss';

const ListItemHeader = ({ icon, title, subTitle }) => {
  return (
    <div className="d-flex">
      <div className="icon">{icon}</div>
      <div className={`titles ${!subTitle ? 'd-flex ai-center' : ''}`}>
        <div className="title">{title}</div>
        {subTitle && <div className="sub-title">{subTitle}</div>}
      </div>
    </div>
  );
};

export default ListItemHeader;
