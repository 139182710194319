import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService from '../utils/APIService';
import { thunkResult } from '../utils/thunkResult';
import moment from 'moment';

const initialState = {
  orders: [],
  loading: false,
  updating: false,
  currentOrder: null,
  updatedAt: 0,
  currentType: 'PAYMENT_PENDING',
};

export const fetchPaginatedOrders = createAsyncThunk('orders/fetch', async (_, { rejectWithValue, getState }) => {
  const type = getType(getState());
  const [err, data] = await to(APIService.get(`order/current-outlet/${type}`));
  return thunkResult(err, data.results, rejectWithValue, eng.unableToFetchData);
});

export const updateOrderStatus = createAsyncThunk(
  'order/update-status',
  async ({ code, status }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`order/status/${status}/${code}`));
    return thunkResult(err, { code, status }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setCurrentOrder: (state, { payload }) => {
      state.currentOrder = payload;
    },
    setCurrentType: (state, { payload }) => {
      state.currentType = payload;
    },
  },
  extraReducers: {
    [fetchPaginatedOrders.pending]: (state) => {
      state.loading = true;
      state.orders = [];
    },
    [fetchPaginatedOrders.rejected]: (state) => {
      state.loading = false;
    },
    [fetchPaginatedOrders.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.orders = payload;
      state.updatedAt = Date.now();
    },
  },
});

export const { setCurrentOrder, setCurrentType } = orderSlice.actions;

const order = (state) => state.order;

// selectors
export const getOrderLoading = createSelector(order, (o) => o.loading);
export const getOrderUpdating = createSelector(order, (o) => o.updating);
export const getOrderList = createSelector(order, (o) => o.orders);
export const getOrderListUpdatedAt = createSelector(order, (o) => moment(new Date(o.updatedAt)).format('HH:MM:ss'));
export const getType = createSelector(order, (o) => o.currentType);

export default orderSlice.reducer;
