import React, { useEffect, useState } from 'react';
import { Checkbox, Input } from 'antd';
import { useField, useFormikContext } from 'formik';

const FormField = ({ labelText, className, InputComp, extraOnChange, ...rest }) => {
  const [field, meta] = useField(rest);
  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label">{labelText}</div>
      {InputComp ? (
        <InputComp
          {...field}
          {...rest}
          onChange={(...params) => {
            field.onChange(field.name)(...params);
            extraOnChange && extraOnChange(...params);
          }}
        />
      ) : (
        <Input {...field} {...rest} />
      )}
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export const InputFiledWithNone = (props) => {
  const [isNone, setNone] = useState(false);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(props.name, isNone ? 'None' : '');
  }, [isNone]);

  return (
    <FormField
      addonAfter={<Checkbox onChange={(e) => setNone(e.target.checked)}>None</Checkbox>}
      {...props}
      disabled={isNone}
    />
  );
};

export const NonFormikFormField = ({ labelText, className, InputComp, onChange, style, ...rest }) => {
  return (
    <div className={`form-field ${className || ''}`} style={style}>
      <div className="label">{labelText}</div>
      {InputComp ? <InputComp {...rest} onChange={onChange} /> : <Input {...rest} onChange={onChange} />}
    </div>
  );
};

export const FormFieldOverrideOnChange = ({ labelText, className, InputComp, ...rest }) => {
  const [field, meta] = useField(rest);
  const { setFieldValue } = useFormikContext();
  return (
    <div className={`form-field ${meta.touched && meta.error ? 'error' : ''} ${className || ''}`}>
      <div className="label">{labelText}</div>
      <InputComp
        {...field}
        {...rest}
        onChange={(value) => {
          setFieldValue(rest.name, value);
        }}
      />
      {meta.touched && meta.error && <span className="error-message">{meta.error}</span>}
    </div>
  );
};

export default FormField;
