import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService, { POST_IMAGE } from '../utils/APIService';
import { thunkResult } from '../utils/thunkResult';
import get from 'lodash.get';

const initialState = {
  promos: [],
  highlights: [],
  loading: false,
};

export const fetchAllPromos = createAsyncThunk('promos/fetch-all', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/promotion-and-food-highlight/promotion'));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const fetchAllHighlights = createAsyncThunk('highlights/fetch-all', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/promotion-and-food-highlight/food-category-highlight'));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const addNewPromotion = createAsyncThunk('promos/add', async ({ formValue, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  const promotionImageUrl = get(data, ['data', 'secure_url'], null);

  [err, data] = await to(
    APIService.post('admin/promotion-and-food-highlight/promotion', { ...formValue, promotionImageUrl, enabled: true }),
  );
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.unableToUpdate);
});

export const enableDisabledPromotion = createAsyncThunk(
  'promos/enable-disable',
  async ({ code, enabled }, { rejectWithValue }) => {
    const [err] = await to(
      APIService.post(`admin/promotion-and-food-highlight/promotion/${code}/${enabled ? 'enable' : 'disable'}`),
    );
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const addNewHighlight = createAsyncThunk('highlight/add', async ({ formValue, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  const imageUrl = get(data, ['data', 'secure_url'], null);

  [err, data] = await to(
    APIService.post('admin/promotion-and-food-highlight/food-category-highlight', { ...formValue, imageUrl }),
  );
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.unableToUpdate);
});

export const enableHighlight = createAsyncThunk('promos/enable', async ({ code }, { rejectWithValue }) => {
  const [err] = await to(APIService.post(`admin/promotion-and-food-highlight/food-category-highlight/${code}/enable`));
  return thunkResult(err, { code }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
});

export const promosHighlightSlice = createSlice({
  name: 'promosHighlights',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllPromos.pending]: (store) => {
      store.loading = true;
    },
    [fetchAllPromos.rejected]: (store) => {
      store.loading = false;
    },
    [fetchAllPromos.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.promos = payload;
    },
    [fetchAllHighlights.pending]: (store) => {
      store.loading = true;
    },
    [fetchAllHighlights.rejected]: (store) => {
      store.loading = false;
    },
    [fetchAllHighlights.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.highlights = payload;
    },
    [enableDisabledPromotion.pending]: (store) => {
      store.loading = true;
    },
    [enableDisabledPromotion.rejected]: (store) => {
      store.loading = false;
    },
    [enableDisabledPromotion.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.promos.filter((a) => a.promotionCode === payload.code)[0].enabled = payload.enabled;
    },
    [enableHighlight.pending]: (store) => {
      store.loading = true;
    },
    [enableHighlight.rejected]: (store) => {
      store.loading = false;
    },
    [enableHighlight.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.highlights = store.highlights.map((a) => ({ ...a, isEnabled: a.code === payload.code }));
    },
    [addNewPromotion.pending]: (store) => {
      store.loading = true;
    },
    [addNewPromotion.rejected]: (store) => {
      store.loading = false;
    },
    [addNewPromotion.fulfilled]: (store) => {
      store.loading = false;
    },
    [addNewHighlight.pending]: (store) => {
      store.loading = true;
    },
    [addNewHighlight.rejected]: (store) => {
      store.loading = false;
    },
    [addNewHighlight.fulfilled]: (store) => {
      store.loading = false;
    },
  },
});

const getStore = (state) => state.promosHighlights;

// selectors
export const getPromosAndHighlightLoading = createSelector(getStore, (store) => store.loading);
export const getAllPromos = createSelector(getStore, (store) => store.promos);
export const getAllHighlights = createSelector(getStore, (store) => store.highlights);

export default promosHighlightSlice.reducer;
