import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPromosAndHighlightLoading } from '../../features/promos-highlights.store';
import to from 'await-to-js';
import SubPageLayout from './helpers/SubPageLayout';
import { Button } from 'antd';
import FormSection from './helpers/FormSection';
import { Form, Formik } from 'formik';
import FormField from './helpers/FormField';
import { CommonDropdown } from './helpers/enums-dropdown';
import * as Yup from 'yup';
import { phoneRegEx } from '../../utils/constant';
import { fetchAllOutlets, getOutlets, isOutletLoading } from '../../features/outlets.store';
import { addNewUser } from '../../features/users.store';

const UserAddForm = () => {
  const history = useHistory();
  const loading = useSelector(getPromosAndHighlightLoading);
  const dispatch = useDispatch();

  let outlets = useSelector(getOutlets).map(({ outletCode, outletName }) => ({ label: outletName, value: outletCode }));
  const outletLoading = useSelector(isOutletLoading);

  useEffect(() => {
    dispatch(fetchAllOutlets());
  }, []);

  const onSubmit = async (values) => {
    const [err] = await to(dispatch(addNewUser(values)).unwrap());
    if (!err) {
      history.push('/users');
    }
  };

  return (
    <SubPageLayout title="Add New User" extra={<Button onClick={() => history.goBack()}>Cancel</Button>}>
      <FormSection title="Add your new user information and submit">
        <Formik
          initialValues={{ email: '', name: '', telephoneNo: '', outletCode: '' }}
          onSubmit={onSubmit}
          className="d-flex f-col"
          validationSchema={UserValidator}
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="email" type="email" labelText="User Email" placeholder="Input here" />
                <FormField name="name" labelText="User Name" placeholder="Input here" />
              </div>
              <div className="flex">
                <FormField
                  name="outletCode"
                  labelText="Outlet"
                  placeholder="Select here"
                  loading={outletLoading}
                  InputComp={(props) => <CommonDropdown placeholder="Select here" options={outlets || []} {...props} />}
                />
                <FormField name="telephoneNo" labelText="User Telephone Number" placeholder="Input here" />
              </div>
            </div>
            <div className="d-flex jc-end space-top-p space-bottom">
              <Button>clear</Button>
              <Button type="primary" htmlType="submit" className="space-left" loading={loading}>
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </FormSection>
    </SubPageLayout>
  );
};

const UserValidator = Yup.object({
  email: Yup.string().email().required('Required'),
  name: Yup.string().required('Required'),
  telephoneNo: Yup.string().required('Required').matches(phoneRegEx, 'Invalid Phone Number'),
  outletCode: Yup.string().required('Required'),
});

export default UserAddForm;
