import React, { useState } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Form, Formik } from 'formik';
import FormField from './helpers/FormField';
import { Button, Input } from 'antd';
import FormSection from './helpers/FormSection';
import to from 'await-to-js';
import { Auth } from '@aws-amplify/auth';
import { toast } from 'react-toastify';

const Profile = () => {
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = async (values) => {
    setLoading(true);
    const { oldPass, newPass, confirmPass } = values;
    // validation
    if (newPass !== confirmPass) {
      setLoading(false);
      return toast.warn('Password does not match!');
    }

    const user = await Auth.currentAuthenticatedUser();
    const [err, data] = await to(Auth.changePassword(user, oldPass, newPass));
    if (err) {
      toast.error("Something went wrong. couldn't update the password");
    } else if (data) {
      toast.success('Password updated!');
    }
    setLoading(false);
  };

  return (
    <SubPageLayout title="Your Profile">
      <FormSection title="Update Your Passwords">
        <Formik
          initialValues={{ oldPass: '', newPass: '', confirmPass: '' }}
          onSubmit={handleOnSubmit}
          className="d-flex f-col"
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField
                  type="password"
                  name="oldPass"
                  labelText="Current Password"
                  InputComp={Input.Password}
                  visibilityToggle={false}
                  placeholder="Input here"
                />
                <FormField
                  type="password"
                  name="newPass"
                  labelText="Current Password"
                  visibilityToggle={false}
                  InputComp={Input.Password}
                  placeholder="Input here"
                />
                <FormField
                  visibilityToggle={false}
                  type="password"
                  name="confirmPass"
                  labelText="Current Password"
                  InputComp={Input.Password}
                  placeholder="Input here"
                />
                <div className="d-flex jc-end space-top-p space-bottom">
                  <Button type="primary" htmlType="submit" className="space-left" loading={loading}>
                    Submit
                  </Button>
                </div>
              </div>
              <div className="flex" />
            </div>
          </Form>
        </Formik>
      </FormSection>
    </SubPageLayout>
  );
};

export default Profile;
