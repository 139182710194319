import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService, { POST_IMAGE } from '../utils/APIService';
import { thunkResult } from '../utils/thunkResult';
import get from 'lodash.get';
import { getUserGroup } from './auth.store';

const initialState = {
  foods: [],
  singleFood: null,
  loading: false,
  manageAddonFor: null,
};

export const fetchPaginatedFoodItems = createAsyncThunk('foods/fetch', async (_, { rejectWithValue, getState }) => {
  const group = getUserGroup(getState());
  let url = 'food-item';
  if (group === 'admin') {
    url = 'admin/food-item';
  }
  const [err, data] = await to(APIService.get(url));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const fetchFoodItemById = createAsyncThunk('foods/fetch-by-id', async ({ id }, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get(`admin/food-item/${id}`));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

// admin only
export const addNewFoodItem = createAsyncThunk('foods/add', async ({ values, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  const imageUrl = get(data, ['data', 'secure_url'], null);
  [err, data] = await to(
    APIService.post('admin/food-item', { ...values, isPopular: false, isFeatured: false, imageUrl }),
  );
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.successfullyAdded);
});

export const updateFoodItem = createAsyncThunk(
  'foods/update',
  async ({ foodCode, value, image }, { rejectWithValue }) => {
    const [err, data] = await to(APIService.put(`/admin/food-item/${foodCode}`, value));
    return thunkResult(err, data, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const setPopularProducts = createAsyncThunk(
  'food/set-popular',
  async ({ code, enabled }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`/admin/food-item/popular/${enabled ? 'add' : 'remove'}/${code}`));
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const setEnableDisableFood = createAsyncThunk(
  'food/set-enableDisabled',
  async ({ code, enabled }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`/food-item/${enabled ? 'enable' : 'disable'}/${code}`));
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const setFeaturedProducts = createAsyncThunk(
  'food/set-featured',
  async ({ code, enabled }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`/admin/food-item/featured/${enabled ? 'add' : 'remove'}/${code}`));
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const addRemoveAddonGroup = createAsyncThunk(
  'food/add-remove-addon-group',
  async ({ code, enabled }, { rejectWithValue, getState }) => {
    const manageAddonFor = getManageAddonsFor(getState());
    const [err] = await to(
      APIService.post(`/admin/food-item/${manageAddonFor.foodCode}/add-on/${enabled ? 'assign' : 'remove'}/${code}`),
    );
    return thunkResult(err, { code, enabled }, rejectWithValue, eng.unableToUpdate);
  },
);

export const foodItemsSlice = createSlice({
  name: 'foods',
  initialState,
  reducers: {
    setManageAddonFor: (store, { payload }) => {
      store.manageAddonFor = payload;
    },
  },
  extraReducers: {
    [fetchPaginatedFoodItems.pending]: (store) => {
      store.loading = true;
    },
    [fetchPaginatedFoodItems.rejected]: (store) => {
      store.loading = false;
      store.foods = [];
    },
    [fetchPaginatedFoodItems.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.foods = payload;
    },
    [fetchFoodItemById.pending]: (store) => {
      store.loading = true;
    },
    [fetchFoodItemById.rejected]: (store) => {
      store.loading = false;
      store.singleFood = null;
    },
    [fetchFoodItemById.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.singleFood = payload;
    },
    [addNewFoodItem.pending]: (store) => {
      store.loading = true;
    },
    [addNewFoodItem.rejected]: (store) => {
      store.loading = false;
    },
    [addNewFoodItem.fulfilled]: (store) => {
      store.loading = false;
    },

    [setPopularProducts.pending]: (store) => {
      store.loading = true;
    },
    [setPopularProducts.rejected]: (store) => {
      store.loading = false;
    },
    [setPopularProducts.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.foods.filter((a) => a.foodCode === payload.code)[0].isPopular = payload.enabled;
    },
    [setFeaturedProducts.pending]: (store) => {
      store.loading = true;
    },
    [setFeaturedProducts.rejected]: (store) => {
      store.loading = false;
    },
    [setFeaturedProducts.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.foods.filter((a) => a.foodCode === payload.code)[0].isFeatured = payload.enabled;
    },
    [addRemoveAddonGroup.fulfilled]: (store, { payload }) => {
      if (payload.enabled) {
        store.manageAddonFor.addOnGroups.push({ addOnGroupCode: payload.code });
      } else {
        store.manageAddonFor.addOnGroups = store.manageAddonFor.addOnGroups.filter(
          ({ addOnGroupCode }) => addOnGroupCode !== payload.code,
        );
      }
      store.foods = store.foods.map((a) => (a.foodCode === store.manageAddonFor.foodCode ? store.manageAddonFor : a));
    },
    [setEnableDisableFood.pending]: (store) => {
      store.loading = true;
    },
    [setEnableDisableFood.rejected]: (store) => {
      store.loading = false;
    },
    [setEnableDisableFood.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.foods.filter((a) => a.foodCode === payload.code)[0].enabled = payload.enabled;
    },
  },
});

export const { setManageAddonFor } = foodItemsSlice.actions;

const getFoodItemsStore = (state) => state.foods;

// selectors
export const getFoodItems = createSelector(getFoodItemsStore, (store) => store.foods || []);
export const getSingleFoodItem = createSelector(getFoodItemsStore, (store) => store.singleFood);
export const isFoodItemsLoading = createSelector(getFoodItemsStore, (store) => store.loading);
export const getManageAddonsFor = createSelector(getFoodItemsStore, (store) => store.manageAddonFor);

export default foodItemsSlice.reducer;
