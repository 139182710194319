import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService, { POST_IMAGE } from '../utils/APIService';
import { thunkResult } from '../utils/thunkResult';
import get from 'lodash.get';

const initialState = {
  addons: [],
  addonGroups: [],
  loading: false,
  groupLoading: false,
  adding: false,
};

export const fetchAllAddons = createAsyncThunk('addon/fetch-all', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/add-on/item'));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const fetchAllAddOnGroups = createAsyncThunk('addon-groups/fetch-all', async (_, { rejectWithValue }) => {
  const [err, data] = await to(APIService.get('admin/add-on/group'));
  return thunkResult(err, data, rejectWithValue, eng.unableToFetchData);
});

export const addNewAddonItem = createAsyncThunk('addon/add', async ({ formValue, image }, { rejectWithValue }) => {
  let err, data;
  if (image) {
    [err, data] = await to(POST_IMAGE(image));
    if (err) {
      return rejectWithValue({ err });
    }
  }
  const imageUrl = get(data, ['data', 'secure_url'], null);

  [err, data] = await to(APIService.post('admin/add-on/item', { ...formValue, imageUrl }));
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.unableToUpdate);
});

export const addNewAddonGroup = createAsyncThunk('addon-group/add', async (formValue, { rejectWithValue }) => {
  const [err, data] = await to(APIService.post('admin/add-on/group', formValue));
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.unableToUpdate);
});

export const assignAddonToGroup = createAsyncThunk(
  'addon-group/assign',
  async ({ groupCode, itemCode }, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post(`admin/add-on/group/${groupCode}/item/${itemCode}`, {}));
    dispatch(fetchAllAddOnGroups());
    return thunkResult(err, data, rejectWithValue, eng.unableToUpdate);
  },
);

export const releaseItemFromGroup = createAsyncThunk(
  'addon-group/release',
  async ({ groupCode, itemCode }, { rejectWithValue, dispatch }) => {
    const [err, data] = await to(APIService.post(`admin/add-on/item/release/${itemCode}`, {}));
    dispatch(fetchAllAddOnGroups());
    return thunkResult(err, data, rejectWithValue, eng.unableToUpdate);
  },
);

export const addonSlice = createSlice({
  name: 'addon',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAllAddons.pending]: (store) => {
      store.loading = true;
    },
    [fetchAllAddons.rejected]: (store) => {
      store.loading = false;
    },
    [fetchAllAddons.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.addons = payload;
    },
    [fetchAllAddOnGroups.pending]: (store) => {
      store.groupLoading = true;
    },
    [fetchAllAddOnGroups.rejected]: (store) => {
      store.groupLoading = false;
    },
    [fetchAllAddOnGroups.fulfilled]: (store, { payload }) => {
      store.groupLoading = false;
      store.addonGroups = payload;
    },
    [addNewAddonItem.pending]: (store) => {
      store.adding = true;
    },
    [addNewAddonItem.rejected]: (store) => {
      store.adding = false;
    },
    [addNewAddonItem.fulfilled]: (store, { payload }) => {
      store.adding = false;
      store.addons.push(payload);
    },
    [addNewAddonGroup.pending]: (store) => {
      store.adding = true;
    },
    [addNewAddonGroup.rejected]: (store) => {
      store.adding = false;
    },
    [addNewAddonGroup.fulfilled]: (store, { payload }) => {
      store.adding = false;
      store.addonGroups.push(payload);
    },
    [assignAddonToGroup.pending]: (store) => {
      store.groupLoading = true;
    },
    [assignAddonToGroup.rejected]: (store) => {
      store.groupLoading = false;
    },
    [assignAddonToGroup.fulfilled]: (store) => {
      store.groupLoading = false;
    },
    [releaseItemFromGroup.pending]: (store) => {
      store.groupLoading = true;
    },
    [releaseItemFromGroup.rejected]: (store) => {
      store.groupLoading = false;
    },
    [releaseItemFromGroup.fulfilled]: (store) => {
      store.groupLoading = false;
    },
  },
});

const getStore = (state) => state.addon;

// selectors
export const getAddonLoading = createSelector(getStore, (store) => store.loading);
export const getAddonGroupLoading = createSelector(getStore, (store) => store.groupLoading);
export const getAllAddonItems = createSelector(getStore, (store) => store.addons);
export const getAllAddonGroup = createSelector(getStore, (store) => store.addonGroups);
export const getAddingInProgress = createSelector(getStore, (store) => store.adding);

export default addonSlice.reducer;
