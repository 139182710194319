import React from 'react';
import Amplify from '@aws-amplify/auth';
import AppRouter from './pages/AppRouter';
import history from './utils/history';
import { Router } from 'react-router-dom';

/* eslint-disable no-undef */
Amplify.configure({
  Auth: {
    userPoolId: process.env.REACT_APP_USER_POOL_ID, //UserPool ID
    region: process.env.REACT_APP_AWS_REGION,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEBCLIENT_ID, //WebClientId
  },
});

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <AppRouter />
      </Router>
    </div>
  );
}

export default App;
