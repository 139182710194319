import React, { useEffect } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Button, Collapse, Descriptions, Switch } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import ListItemHeader from './helpers/ListItemHeader';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { enableDisabledOutlet, fetchPaginatedOutlets, getOutlets, isOutletLoading } from '../../features/outlets.store';
import { MdOutlineStorefront } from 'react-icons/md';
import { useFeature } from '../../utils/features';
const { Panel } = Collapse;

const Outlets = () => {
  const dispatch = useDispatch();
  const outlets = useSelector(getOutlets);
  const loading = useSelector(isOutletLoading);

  const addOutlet = useFeature('add_outlet');
  const editOutlet = useFeature('edit_outlet');

  useEffect(() => {
    dispatch(fetchPaginatedOutlets());
  }, []);

  const handleEnableDisabled = (code, enabled) => {
    dispatch(enableDisabledOutlet({ code, enabled }));
  };

  return (
    <SubPageLayout
      title="Outlets"
      loading={loading}
      extra={
        addOutlet && (
          <Link to="outlets/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Add New
            </Button>
          </Link>
        )
      }
    >
      <Collapse
        accordion
        ghost
        expandIconPosition="right"
        collapsible="header"
        defaultActiveKey={!addOutlet && outlets[0] && outlets[0].outletCode}
      >
        {outlets.map((outlet) => (
          <Panel
            key={outlet.outletCode}
            header={
              <ListItemHeader
                icon={<MdOutlineStorefront />}
                title={outlet.outletName}
                subTitle={`Location: ${outlet.outletAddress}`}
              />
            }
            extra={
              editOutlet && [
                <Switch
                  key="enable-disabled-switch"
                  onChange={(checked) => {
                    handleEnableDisabled(outlet.outletCode, checked);
                  }}
                  checkedChildren="ENABLE"
                  unCheckedChildren="DISABLE"
                  checked={outlet.enabled}
                />,
              ]
            }
            className="list-item"
          >
            <Descriptions>
              <Descriptions.Item label="Address">{outlet.outletAddress}</Descriptions.Item>
              <Descriptions.Item label="Telephone">{outlet.phoneNumber}</Descriptions.Item>
            </Descriptions>
            <div>
              {editOutlet && (
                <Link to={`/outlets/${outlet.outletCode}/edit`}>
                  <Button type="default" shape="circle" icon={<EditOutlined />} />
                </Link>
              )}
            </div>
          </Panel>
        ))}
      </Collapse>
    </SubPageLayout>
  );
};

export default Outlets;
