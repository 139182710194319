import React, { useRef } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormField from './helpers/FormField';
import FormSection from './helpers/FormSection';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addNewPromotion, getPromosAndHighlightLoading } from '../../features/promos-highlights.store';
import { Uploader } from './helpers/Uploader';
import get from 'lodash.get';
import to from 'await-to-js';

const PromosAdd = () => {
  const history = useHistory();
  const loading = useSelector(getPromosAndHighlightLoading);
  const dispatch = useDispatch();
  const imageUploadRef = useRef();

  const onSubmit = async (values) => {
    const image = get(imageUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    const [err] = await to(dispatch(addNewPromotion({ formValue: values, image })).unwrap());
    if (!err) {
      history.push('/promos');
    }
  };

  return (
    <SubPageLayout title="Add New Promotion" extra={<Button onClick={() => history.goBack()}>Cancel</Button>}>
      <FormSection title="Add your new promo information and submit">
        <Formik
          initialValues={{ promotionName: '' }}
          onSubmit={onSubmit}
          className="d-flex f-col"
          validationSchema={PromoValidator}
        >
          <Form>
            <div className="d-flex">
              <div className="flex space-right">
                <FormField name="promotionName" labelText="Promotion Name" placeholder="Input here" />
              </div>
              <div className="flex">
                <Uploader
                  accept="image/*"
                  beforeUpload={() => Promise.reject()}
                  className="w-200px h-200px"
                  maxCount={1}
                  ref={imageUploadRef}
                />
              </div>
            </div>
            <div className="d-flex jc-end space-top-p space-bottom">
              <Button>clear</Button>
              <Button type="primary" htmlType="submit" className="space-left" loading={loading}>
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </FormSection>
    </SubPageLayout>
  );
};

const PromoValidator = Yup.object({
  promotionName: Yup.string().required('Required'),
});

export default PromosAdd;
