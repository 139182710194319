import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Descriptions, Empty, Image, Switch, Tabs } from 'antd';
import { EditOutlined, PlusOutlined, RiseOutlined, StarOutlined } from '@ant-design/icons';
import SubPageLayout from './helpers/SubPageLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getEnumObj, getFoodCategoriesArray } from '../../features/enums.store';
import {
  fetchPaginatedFoodItems,
  getFoodItems,
  isFoodItemsLoading,
  setEnableDisableFood,
  setFeaturedProducts,
  setManageAddonFor,
  setPopularProducts,
} from '../../features/foods.store';
import ListItemHeader from './helpers/ListItemHeader';
import { MdOutlineStorefront } from 'react-icons/md';
import { resizeCloudinaryImages } from '../../utils/images';
import AddonManager from './helpers/AddonManager';
import { useFeature } from '../../utils/features';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const Foods = () => {
  const dispatch = useDispatch();
  const featureAddFood = useFeature('add_foods');
  const featureEditFood = useFeature('edit_foods');
  const featureEnableDisabledFood = useFeature('enable_disabled_foods');

  const foodCategories = useSelector(getFoodCategoriesArray);
  const dietaryPreferencesEnum = useSelector(getEnumObj)('dietary-preferences');
  const foodItemsLoading = useSelector(isFoodItemsLoading);
  const foodItems = useSelector(getFoodItems);

  useEffect(() => {
    dispatch(fetchPaginatedFoodItems());
  }, []);

  const handleChangeFeatured = (code, value) => {
    dispatch(setFeaturedProducts({ code, enabled: value }));
  };

  const handleChangePopular = (code, value) => {
    dispatch(setPopularProducts({ code, enabled: value }));
  };

  const handleEnableDisabled = (code, value) => {
    dispatch(setEnableDisableFood({ code, enabled: value }));
  };

  return (
    <SubPageLayout
      title="Food Items"
      loading={foodItemsLoading}
      extra={
        featureAddFood && (
          <Link to="foods/add">
            <Button type="primary" icon={<PlusOutlined />}>
              Add New
            </Button>
          </Link>
        )
      }
    >
      {featureEditFood && <AddonManager />}
      <Tabs type="card" size="large">
        {foodCategories.map(({ label, value }) => {
          const items = foodItems.filter(({ foodCategory }) => foodCategory === value);
          return (
            <TabPane tab={label} key={value} className="p-5">
              {items.length ? (
                <Collapse accordion ghost expandIconPosition="right" collapsible="header">
                  {items.map((food) => (
                    <Panel
                      key={food.foodCode}
                      header={
                        <ListItemHeader
                          icon={<MdOutlineStorefront />}
                          title={food.foodName}
                          subTitle={`Dietary Preferences: ${dietaryPreferencesEnum[food.dietaryPreferences]}`}
                        />
                      }
                      extra={
                        featureEditFood
                          ? [
                              <Button onClick={() => dispatch(setManageAddonFor(food))} key="manage-addons">
                                Manage Addons
                              </Button>,
                              <Button
                                key="featured"
                                type={food.isFeatured ? 'primary' : 'default'}
                                shape="circle"
                                className="space-left"
                                icon={<StarOutlined />}
                                onClick={() => handleChangeFeatured(food.foodCode, !food.isFeatured)}
                              />,
                              <Button
                                key="popular"
                                type={food.isPopular ? 'primary' : 'default'}
                                shape="circle"
                                className="space-left"
                                icon={<RiseOutlined />}
                                onClick={() => handleChangePopular(food.foodCode, !food.isPopular)}
                              />,
                            ]
                          : featureEnableDisabledFood && [
                              <Switch
                                key="enable-disabled-switch"
                                onChange={(checked) => {
                                  handleEnableDisabled(food.foodCode, checked);
                                }}
                                checkedChildren="ENABLE"
                                unCheckedChildren="DISABLE"
                                checked={food.enabled}
                              />,
                            ]
                      }
                      className="list-item"
                    >
                      <Descriptions column={4}>
                        <Descriptions.Item label="Nutrition Calories">{food.foodNutritionCalories}</Descriptions.Item>
                        <Descriptions.Item label="Nutrition Carbs">{food.foodNutritionCarbs}</Descriptions.Item>
                        <Descriptions.Item label="Nutrition Fat">{food.foodNutritionFat}</Descriptions.Item>
                        <Descriptions.Item label="Nutrition Protein">{food.foodNutritionProtein}</Descriptions.Item>
                        <Descriptions.Item span={4} label="Description">
                          {food.description}
                        </Descriptions.Item>
                        <Descriptions.Item label="Image">
                          <Image src={resizeCloudinaryImages(food.imageUrl, 0, 200)} preview={{ src: food.imageUrl }} />
                        </Descriptions.Item>
                      </Descriptions>
                      {featureEditFood && (
                        <div>
                          <Link to={`/outlets/${food.foodCode}/edit`}>
                            <Button type="default" shape="circle" icon={<EditOutlined />} />
                          </Link>
                        </div>
                      )}
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <Empty />
              )}
            </TabPane>
          );
        })}
      </Tabs>
    </SubPageLayout>
  );
};

export default Foods;
