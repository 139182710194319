import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import { toast, ToastContainer } from 'react-toastify';
import history from '../utils/history';
import { useDispatch, useSelector } from 'react-redux';
import LoadingScreen from './LoadingScreen';
import eng from '../utils/eng';
import ForgetPassword from './Login/forget-password';
import ChangePassword from './Login/change-password';
import to from 'await-to-js';
import { getIsValidating, validateCurrentSession } from '../features/auth.store';

const AppRouter = () => {
  const dispatch = useDispatch();

  const isValidating = useSelector(getIsValidating);

  useEffect(() => {
    (async () => {
      const [err] = await to(dispatch(validateCurrentSession()).unwrap());
      if (err) {
        toast.error(eng.pleaseLoginAgain);
        history.push('/login');
      }
    })();
  }, []);

  return isValidating ? (
    <LoadingScreen />
  ) : (
    <>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgetPassword} />
        <Route path="/change-forgot-password" component={ChangePassword} />
        <Route path="/dashboard" component={Dashboard} />
        <Redirect from="/" to="/login" />
      </Switch>
      <ToastContainer />
    </>
  );
};

export default AppRouter;
