import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import eng from '../utils/eng';
import to from 'await-to-js';
import APIService from '../utils/APIService';
import { toast } from 'react-toastify';
import { thunkResult } from '../utils/thunkResult';

const initialState = {
  users: [],
  outlet: '',
  pageNumber: 0,
  paginationTokens: [],
  loading: false,
};

export const fetchPaginatedUsers = createAsyncThunk(
  'users/fetch',
  async ({ outlet, direction }, { getState, rejectWithValue }) => {
    const tokens = getPaginationTokens(getState());
    const pageNumber = getPageNumber(getState());
    let fetchPage = pageNumber + (direction === 'next' ? 1 : -1);
    if (outlet !== getSelectedOutlet(getState())) {
      fetchPage = 0;
    }
    const paginationToken = tokens[fetchPage] || null;
    const [err, data] = await to(
      APIService.post('admin/user/get-all', { paginationToken }, { params: { outletCode: outlet } }),
    );
    if (err) {
      toast.error(eng.unableToFetchData);
      return rejectWithValue(err);
    }
    return { data, fetchPage };
  },
);

export const addNewUser = createAsyncThunk('user/add', async (values, { rejectWithValue }) => {
  const [err, data] = await to(APIService.post('admin/user', values));
  return thunkResult(err, data, rejectWithValue, eng.unableToAdd, eng.successfullyAdded);
});

export const enableDisabledUsers = createAsyncThunk(
  'user/enable-disabled',
  async ({ username, enabled }, { rejectWithValue }) => {
    const [err] = await to(APIService.post(`admin/user/${enabled ? 'enable' : 'disable'}/${username}`, {}));
    return thunkResult(err, { username, enabled }, rejectWithValue, eng.unableToUpdate, eng.successfullyUpdated);
  },
);

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPaginatedUsers.pending]: (store) => {
      store.loading = true;
    },
    [fetchPaginatedUsers.rejected]: (store) => {
      store.loading = false;
    },
    [fetchPaginatedUsers.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.users = payload.data.users;
      store.paginationTokens[payload.fetchPage + 1] = payload.data.paginationToken;
      store.pageNumber = payload.fetchPage;
    },
    [addNewUser.pending]: (store) => {
      store.loading = true;
    },
    [addNewUser.fulfilled]: (store) => {
      store.loading = false;
    },
    [addNewUser.rejected]: (store) => {
      store.loading = false;
    },
    [enableDisabledUsers.pending]: (store) => {
      store.loading = true;
    },
    [enableDisabledUsers.fulfilled]: (store, { payload }) => {
      store.loading = false;
      store.users.filter((a) => a.username === payload.username)[0].enabled = payload.enabled;
    },
    [enableDisabledUsers.rejected]: (store) => {
      store.loading = false;
    },
  },
});

export const { setLoading, logoutUser } = usersSlice.actions;

const getUsersStore = (state) => state.users;

// selectors
export const getPaginationTokens = createSelector(getUsersStore, (store) => store.paginationTokens);
export const getPageNumber = createSelector(getUsersStore, (store) => store.pageNumber);
export const isUsersLoading = createSelector(getUsersStore, (store) => store.loading);
export const getUsers = createSelector(getUsersStore, (store) => store.users);
export const getSelectedOutlet = createSelector(getUsersStore, (store) => store.outlet);

export default usersSlice.reducer;
