import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  enableHighlight,
  fetchAllHighlights,
  getAllHighlights,
  getPromosAndHighlightLoading,
} from '../../features/promos-highlights.store';
import SubPageLayout from './helpers/SubPageLayout';
import { Link } from 'react-router-dom';
import { Button, Collapse, Image } from 'antd';
import { StarOutlined, PlusOutlined } from '@ant-design/icons';
import ListItemHeader from './helpers/ListItemHeader';
import { MdStarOutline } from 'react-icons/md';

const { Panel } = Collapse;

const Highlights = () => {
  const loading = useSelector(getPromosAndHighlightLoading);
  const allHighlights = useSelector(getAllHighlights);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllHighlights());
  }, []);

  const handleEnable = (code) => {
    dispatch(enableHighlight({ code }));
  };

  return (
    <SubPageLayout
      title="Food Promotions"
      extra={
        <Link to="highlights/add">
          <Button type="primary" icon={<PlusOutlined />}>
            Add New
          </Button>
        </Link>
      }
      loading={loading}
    >
      <Collapse accordion ghost expandIconPosition="right" collapsible="header">
        {allHighlights.map((highlight) => (
          <Panel
            key={highlight.code}
            header={<ListItemHeader icon={<MdStarOutline />} title={highlight.foodCategory} />}
            extra={
              <Button
                type={highlight.isEnabled ? 'primary' : 'default'}
                shape="circle"
                icon={<StarOutlined />}
                onClick={() => handleEnable(highlight.code)}
              />
            }
            className="list-item"
          >
            <Image src={highlight.imageUrl} alt={highlight.promotionImageUrl} />
          </Panel>
        ))}
      </Collapse>
    </SubPageLayout>
  );
};

export default Highlights;
