import React, { useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { Form, Formik } from 'formik';
import FormField from './FormField';
import { Uploader } from './Uploader';
import FormSection from './FormSection';
import { useDispatch, useSelector } from 'react-redux';
import { addNewAddonGroup, addNewAddonItem, getAddingInProgress } from '../../../features/addon.store';
import * as Yup from 'yup';
import { PlusOutlined } from '@ant-design/icons';
import get from 'lodash.get';
import to from 'await-to-js';

export const AddonForm = () => {
  const addingInProgress = useSelector(getAddingInProgress);
  const [visibility, setVisibility] = useState(false);
  const form = useRef();
  const imageUploadRef = useRef();
  const dispatch = useDispatch();

  const handleOK = () => {
    form.current.submitForm();
  };
  const onSubmit = async (values, { resetForm }) => {
    const image = get(imageUploadRef, ['current', 'fileList', 0, 'originFileObj'], null);
    const [err] = await to(dispatch(addNewAddonItem({ formValue: values, image })).unwrap());
    if (!err) {
      setVisibility(false);
      resetForm();
    }
  };

  return (
    <>
      <Button icon={<PlusOutlined />} onClick={() => setVisibility(true)}>
        Add New Item
      </Button>
      <Modal
        title="Add New Addon Item"
        visible={visibility}
        onCancel={() => setVisibility(false)}
        onOk={handleOK}
        okButtonProps={{ loading: addingInProgress }}
        closable={false}
      >
        <FormSection>
          <Formik
            innerRef={form}
            initialValues={{ name: '', price: '' }}
            onSubmit={onSubmit}
            className="d-flex f-col"
            validationSchema={AddonItemValidation}
          >
            <Form>
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField name="name" labelText="Addon Item Name" placeholder="Input here" />
                  <FormField name="price" labelText="Price" placeholder="Input here" />
                </div>
                <div className="flex">
                  <Uploader
                    accept="image/*"
                    beforeUpload={() => Promise.reject()}
                    className="w-200px h-200px"
                    maxCount={1}
                    ref={imageUploadRef}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </FormSection>
      </Modal>
    </>
  );
};

const AddonItemValidation = Yup.object({
  name: Yup.string().required('Required'),
  price: Yup.string().required('Required'),
});

export const AddonGroupForm = () => {
  const addingInProgress = useSelector(getAddingInProgress);
  const [visibility, setVisibility] = useState(false);
  const form = useRef();
  const dispatch = useDispatch();

  const handleOK = () => {
    form.current.submitForm();
  };

  const onSubmit = async (values, { resetForm }) => {
    const [err] = await to(dispatch(addNewAddonGroup(values)).unwrap());
    if (!err) {
      setVisibility(false);
      resetForm();
    }
  };
  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisibility(true)} className="space-left">
        Add New Group
      </Button>
      <Modal
        title="Add New Addon Items Group"
        visible={visibility}
        onCancel={() => setVisibility(false)}
        onOk={handleOK}
        okButtonProps={{ loading: addingInProgress }}
        closable={false}
      >
        <FormSection>
          <Formik
            innerRef={form}
            initialValues={{ name: '' }}
            onSubmit={onSubmit}
            className="d-flex f-col"
            validationSchema={AddonItemsGroupValidation}
          >
            <Form>
              <div className="d-flex">
                <div className="flex space-right">
                  <FormField name="name" labelText="Addon Item Group Name" placeholder="Input here" />
                </div>
              </div>
            </Form>
          </Formik>
        </FormSection>
      </Modal>
    </>
  );
};

const AddonItemsGroupValidation = Yup.object({
  name: Yup.string().required('Required'),
});
