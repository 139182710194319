import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
// import { nonRetryPOST, POST, POST_IMAGE, PUT } from '../utils/APIService';
import eng from '../utils/eng';
import { Auth } from 'aws-amplify';
import to from 'await-to-js';
import history from '../utils/history';
import { toast } from 'react-toastify';
import { fromFeatureMatrix } from '../utils/features';

const UserLogoutAction = createAction('USER_LOGOUT');

const initialState = {
  authData: {},
  session: {},
  isAuthenticated: false,
  isLoading: false,
  isValidating: true,
  isInitialLogin: false,
  changeFormLoading: false,
  group: '',
};

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ email, password, rememberMe }, { rejectWithValue }) => {
    try {
      const [err, authResult] = await to(Auth.signIn(email, password));
      if (err) {
        return rejectWithValue(err);
      }
      if (authResult.challengeName === 'NEW_PASSWORD_REQUIRED') {
        window._tempUserRef = authResult;
        return rejectWithValue({
          forceChangePassword: true,
        });
      }

      const groups = authResult.signInUserSession.accessToken.payload['cognito:groups'] || [];
      let group = '';
      if (groups.indexOf('admins') > -1) {
        group = 'admin';
      } else if (groups.indexOf('employees') > -1) {
        group = 'employee';
      } else {
        toast.error('Unauthorized account for web app');
        await Auth.signOut();
        return rejectWithValue({});
      }

      const { attributes, signInUserSession } = authResult;

      return {
        attributes,
        session: {
          accessToken: signInUserSession.accessToken.token,
          idToken: signInUserSession.idToken.getJwtToken(),
          refreshToken: signInUserSession.refreshToken.token,
        },
        group,
      };
    } catch (e) {
      return rejectWithValue({ message: eng.somethingWentWrong });
    }
  },
);

export const validateCurrentSession = createAsyncThunk('auth/validate', async (_, { rejectWithValue }) => {
  const [err1, currentSession] = await to(Auth.currentSession());
  if (err1) {
    return rejectWithValue(err1);
  }
  if (currentSession.isValid()) {
    const [err2, user] = await to(Auth.currentAuthenticatedUser());
    if (err2) {
      return rejectWithValue(err2);
    }

    const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
    let group;
    if (groups.indexOf('admins') > -1) {
      group = 'admin';
    } else if (groups.indexOf('employees') > -1) {
      group = 'employee';
    } else {
      toast.error('Unauthorized account for web app');
      await Auth.signOut();
      return rejectWithValue({});
    }

    return {
      user: user.attributes,
      session: {
        accessToken: currentSession.accessToken.jwtToken,
        idToken: currentSession.idToken.getJwtToken(),
        refreshToken: currentSession.refreshToken.token,
      },
      group,
    };
  }
  return rejectWithValue({});
});

export const logout = () => async (dispatch) => {
  await Auth.signOut();
  dispatch(UserLogoutAction());
  history.push('/login');
  dispatch(logoutUser());
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    logoutUser: (state) => {
      state.authData = {};
      state.isAuthenticated = false;
      state.isValidating = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.authData = payload.attributes;
      state.session = payload.session;
      state.group = payload.group;
    },
    [loginUser.rejected]: (state) => {
      state.isLoading = false;
      state.authData = {};
    },
    [validateCurrentSession.pending]: (state) => {
      state.isValidating = true;
    },
    [validateCurrentSession.rejected]: (state) => {
      state.isValidating = false;
    },
    [validateCurrentSession.fulfilled]: (state, { payload }) => {
      state.isValidating = false;
      if (payload) {
        state.authData = payload.user;
        state.session = payload.session;
        state.group = payload.group;
      } else {
        console.log('empty payload');
      }
    },
  },
});

export const { setLoading, logoutUser } = authSlice.actions;

const getAuthStore = (state) => state.auth;

// selectors
export const getUser = createSelector(getAuthStore, (state) => state.authData);
export const getIsLoggedIn = createSelector(getUser, (user) => user.email);
export const getLoginInProgress = createSelector(getAuthStore, (state) => state.isLoading);
export const getIsValidating = createSelector(getAuthStore, (state) => state.isValidating);
export const getUserGroup = createSelector(getAuthStore, (state) => state.group);

export const isFeatureEnabled = createSelector(getUserGroup, (group) => (key) => fromFeatureMatrix(group, key));

export default authSlice.reducer;
