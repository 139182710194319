import React, {useEffect, useState} from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './helpers/layout';

import Outlets from './Outlets';
import { OutletAdd, OutletEdit } from './OutletsForm';
import Users from './Users';
import Promotions from './Promotions';
import Highlights from './Highlights';
import PromosAdd from './PromosAdd';
import HighlightAdd from './HighlightAdd';
import Foods from './Foods';
import { FoodsAdd, FoodsEdit } from './FoodsForms';
import AddOns from './AddOns';
import UserAddForm from './UserAddForm';
import Profile from './Profile';
import { useFeature } from '../../utils/features';
import Orders from './Orders';
import {getEnums} from "../../features/enums.store";
import {useDispatch} from "react-redux";
import LoadingScreen from "../LoadingScreen";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const showOutlet = useFeature('view_outlet');
  const addOutlet = useFeature('add_outlet');
  const editOutlet = useFeature('edit_outlet');

  const viewUsers = useFeature('view_users');
  const viewOrders = useFeature('view_orders');

  const viewFoods = useFeature('view_foods');
  const addFoods = useFeature('view_foods');
  const editFoods = useFeature('view_foods');

  const viewPromos = useFeature('view_promos');
  const viewAddons = useFeature('view_addons');
  const viewHighlight = useFeature('view_highlights');

  useEffect(() => {
    (async () => {
      setLoading(true)
      await dispatch(getEnums({ type: 'order-statuses' })).unwrap();
      await dispatch(getEnums({ type: 'food-categories' })).unwrap();
      await dispatch(getEnums({ type: 'dietary-preferences' })).unwrap();
      setLoading(false);
    })();
  }, []);

  return (
    <BrowserRouter basename="/dashboard">
      {loading ? <LoadingScreen/> : <Layout>
        <Switch>
          {addOutlet && <Route path="/outlets/add" component={OutletAdd} />}
          {editOutlet && <Route path="/outlets/:outletCode/edit" component={OutletEdit} />}
          {showOutlet && <Route path="/outlets" component={Outlets} />}

          {viewUsers && <Route path="/users/add" component={UserAddForm} />}
          {viewUsers && <Route path="/users" component={Users} />}

          {viewOrders && <Route path="/orders" component={Orders} />}

          {addFoods && <Route path="/foods/add" component={FoodsAdd} />}
          {editFoods && <Route path="/foods/:foodCode/edit" component={FoodsEdit} />}
          {viewFoods && <Route path="/foods" component={Foods} />}

          {viewPromos && <Route path="/promos/add" component={PromosAdd} />}
          {viewPromos && <Route path="/promos" component={Promotions} />}
          {viewHighlight && <Route path="/highlights/add" component={HighlightAdd} />}
          {viewHighlight && <Route path="/highlights" component={Highlights} />}
          {viewAddons && <Route path="/addons" component={AddOns} />}

          <Route path="/profile" component={Profile} />
          <Redirect from="/" to={showOutlet ? '/outlets' : '/orders'} />
        </Switch>
      </Layout>}
    </BrowserRouter>
  );
};

export default Dashboard;
