export default {
  invalidUsernamePassword: 'Invalid Username or Password!',
  loginSuccess: 'Logged in success!',
  pleaseLoginAgain: 'Please Login again!',

  updatePasswordSuccess: 'Updated Password Successfully!',
  updateProfileInfoSuccess: 'Updated Profile Info Successfully!',

  forgetPasswordRequested: 'Sent an email with rest password link!',
  changeForgotPasswordSuccess: 'Success!. Please try to log in again with new password!',

  unableToAdd: 'Adding is failed! please try again',
  unableToUpdate: 'Updating is failed! please try again',
  successfullyAdded: 'Adding is success!',
  successfullyUpdated: 'Updating is success!',

  somethingWentWrong: 'Something went wrong!',

  unableToFetchData: 'Unable to fetch data, Please try again!',
};
