import { getAllEnum, getEnums } from '../../../features/enums.store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Select } from 'antd';

export const CommonDropdown = (props) => <Select showSearch className="w-100" {...props} />;

export const generateEnumDropdown = (type) => (props) => {
  const dispatch = useDispatch();
  const e = useSelector(getAllEnum)[type];
  const options = Object.keys(e).map((value) => ({ value, label: e[value] }));
  useEffect(() => {
    dispatch(getEnums({ type }));
  }, []);
  return <CommonDropdown options={options || []} loading={!options} {...props} />;
};

export const OrderStatusesDropdown = generateEnumDropdown('order-statuses');
export const FoodCategoriesDropdown = generateEnumDropdown('food-categories');
export const DietaryPreferencesDropdown = generateEnumDropdown('dietary-preferences');
