import React from 'react';
import { Upload } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

export const Uploader = React.forwardRef(({ icon, text, className, ...rest }, ref) => (
  <div className={`uploader h-100 ${className || ''}`}>
    <Dragger {...rest} ref={ref}>
      <p className="ant-upload-drag-icon">{icon ? icon : <CameraOutlined />}</p>
      <p className="ant-upload-text">
        {text ? (
          text
        ) : (
          <>
            <a href="#">Upload</a>
          </>
        )}
      </p>
    </Dragger>
  </div>
));
