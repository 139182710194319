import React, { useEffect, useState } from 'react';
import SubPageLayout from './helpers/SubPageLayout';
import { Button, Col, Collapse, Descriptions, Row, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ListItemHeader from './helpers/ListItemHeader';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOutlets, getOutlets, isOutletLoading } from '../../features/outlets.store';
import { MdOutlineStorefront } from 'react-icons/md';
import { enableDisabledUsers, fetchPaginatedUsers, getUsers, isUsersLoading } from '../../features/users.store';
import { SearchFilterDropdown } from './helpers/SearchFilterDropdown';
const { Panel } = Collapse;

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const loading = useSelector(isUsersLoading);

  const [outlet, setOutlet] = useState(null);

  useEffect(() => {
    if (outlet) {
      dispatch(fetchPaginatedUsers({ outlet, direction: 'next' }));
    }
  }, [outlet]);

  const handleEnableDisabled = (username, enabled) => {
    dispatch(enableDisabledUsers({ username, enabled }));
  };

  return (
    <SubPageLayout
      title="Users"
      loading={loading}
      extra={
        <Link to="users/add">
          <Button type="primary" icon={<PlusOutlined />}>
            Add New
          </Button>
        </Link>
      }
    >
      <Row className="space-bottom">
        <Col>
          <SearchFilterDropdown
            placeholder="Search outlet"
            onChange={setOutlet}
            identifier="outletCode"
            labelKey="outletName"
            selectFirstOnLoad
            dataSelector={getOutlets}
            loadingSelector={isOutletLoading}
            fetchAction={fetchAllOutlets}
          />
        </Col>
      </Row>
      <Collapse accordion ghost expandIconPosition="right" collapsible="header">
        {users.map((user) => (
          <Panel
            key={user.username}
            header={
              <ListItemHeader
                icon={<MdOutlineStorefront />}
                title={user.name}
                subTitle={`Telephone: ${user.telephoneNo}`}
              />
            }
            extra={[
              <Switch
                key="enable-disabled-switch"
                onChange={(checked) => {
                  handleEnableDisabled(user.username, checked);
                }}
                checkedChildren="ENABLE"
                unCheckedChildren="DISABLE"
                checked={user.enabled}
              />,
            ]}
            className="list-item"
          >
            <Descriptions>
              <Descriptions.Item label="Telephone">{user.telephoneNo}</Descriptions.Item>
              <Descriptions.Item label="email">{user.email}</Descriptions.Item>
            </Descriptions>
          </Panel>
        ))}
      </Collapse>
    </SubPageLayout>
  );
};

export default Users;
