import axios from 'axios';
import { Auth } from 'aws-amplify';
import to from 'await-to-js';

/* eslint-disable no-undef */
const BASE_URL = process.env.REACT_APP_BASE_URL;
const CLOUDINARY_UPLOAD_URL = process.env.REACT_APP_IMAGE_UPLOAD;
const CLOUDINARY_PRESET = process.env.REACT_APP_CLOUDINARY_PRESET;
const CLOUDINARY_CLOUD = process.env.REACT_APP_CLOUDINARY_CLOUD;

const getIdToken = async () => {
  const currentSession = await Auth.currentSession();
  return currentSession.getIdToken().getJwtToken();
};

const refreshAccessToken = async () => {
  const congnitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  const [_, newSession] = await to(congnitoUser.refreshToken(currentSession.getRefreshToken()));
  return newSession;
};

const axiosApiInstance = axios.create({
  baseURL: BASE_URL,
});

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    // config.headers = {
    //   Authorization: await getIdToken(),
    //   'content-type': 'application/json',
    //   accept: 'application/json',
    // };
    config.headers.Authorization = await getIdToken();

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      axios.defaults.headers.common['Authorization'] = `${accessToken}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

const POST_IMAGE = (image) => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('upload_preset', CLOUDINARY_PRESET);
  formData.append('cloud_name', CLOUDINARY_CLOUD);
  return axios.post(CLOUDINARY_UPLOAD_URL, formData);
};

const DOWNLOAD = (url) =>
  axios({
    url: getFullUrl(url),
    method: 'GET',
    responseType: 'blob',
    headers: getRequestHeaders(false),
  });

export default axiosApiInstance;
export { POST_IMAGE, DOWNLOAD };
